import { OptionsColor, isString, stringToAlpha } from "tsparticles-engine";
export class PolygonMaskDrawStroke {
    constructor() {
        this.color = new OptionsColor();
        this.width = 0.5;
        this.opacity = 1;
    }
    load(data) {
        if (!data) {
            return;
        }
        this.color = OptionsColor.create(this.color, data.color);
        if (isString(this.color.value)) {
            this.opacity = stringToAlpha(this.color.value) ?? this.opacity;
        }
        if (data.opacity !== undefined) {
            this.opacity = data.opacity;
        }
        if (data.width !== undefined) {
            this.width = data.width;
        }
    }
}
